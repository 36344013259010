<template>
    <div>
        <div class="fixed z-30 w-full">
            <div class="bg-gray-200 bg-opacity-40 backdrop-blur-md lg:bg-transparent flex flex-row justify-between lg:justify-center items-center px-3 h-16 transform transition-all duration-300 ease-linear"
                 :class="slide ? '-translate-y-full' : 'translate-y-0'"
            >
                <a class="cursor-pointer" href="/"><img src="/img/noka.png" alt="logo" class="h-16"></a>
                <div class="lg:hidden" @click="openCloseMobileMenu">
                    <img :src="!expandMobileMenu ? '/img/menu.svg' : '/img/close.svg'" width="30" class="cursor-pointer">
                </div>
            </div>
            <div
                class="px-3 transform transition-all duration-700 overflow-y-hidden lg:overflow-y-visible"
                :class="classObject"
            >
                <div class="max-w-5xl relative mx-auto flex flex-col lg:flex-row space-y-3 md:space-y-0 lg:space-x-10 lg:justify-center lg:items-center lg:h-16">
                    <a
                        v-for="link in links"
                        :key="link.id"
                        :href="link.href"
                        @click="openCloseSubLink(link)"
                        @mouseover="expandSubLink(link)"
                        @mouseleave="collapseSubLink(link)"
                        class="cursor-pointer relative py-2 link"
                    >
                        <div class="flex items-center">
                            {{link.title}}
                            <div v-if="link.sub_links" class="lg:hidden ml-2.5">
                                <img :src="link.is_visible ? '/img/up_white.svg' : '/img/down_white.svg'" width="18">
                            </div>
                        </div>
                        <div class="lg:absolute mt-2 max-h-0 overflow-y-hidden transition-all duration-300 ease-in"
                             :class="link.is_visible ? 'max-h-screen lg:py-6' : 'max-h-0'">
                            <div class="flex flex-col space-y-2 w-72 bg-gray-800 bg-opacity-90 p-3">
                                <a
                                    v-for="sub in link.sub_links"
                                    :key="sub.id"
                                    :href="sub.href"
                                    class="cursor-pointer px-3 relative inline-block py-2 hover:bg-gray-900"
                                >
                                    {{sub.name}}
                                </a>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Navbar",
    data: function() {
        return {
            flags: [
                {
                    id: 0,
                    name: 'ru',
                    flag: '/img/ru.png'
                },
                {
                    id: 1,
                    name: 'kz',
                    flag: '/img/kz.png'
                },
                {
                    id: 2,
                    name: 'en',
                    flag: '/img/en.png'
                }
            ],
            locale: {
                name: 'ru',
                flag: '/img/ru.png'
            },
            links: [
                {
                    id: 0,
                    title: 'Main',
                    href: '/',
                    is_visible: false
                },
                {
                    id: 1,
                    title: 'About',
                    href: '/#about',
                    is_visible: false,
                },
                {
                    id: 2,
                    title: 'Products',
                    href: '/#products',
                    is_visible: false
                },
                {
                  id: 3,
                  title: 'Workflow',
                  href: '/#workflow',
                  is_visible: false
                },
                {
                    id: 4,
                    title: 'Contact',
                    href: '/#contact',
                    is_visible: false
                }
            ],
            expandMobileMenu: false,
            slide: false,
            expandLanguages: false,
            expandSubLinks: false,
        }
    },
    computed: {
        classObject: function (){
            return {
                'max-h-screen ease-in-out bg-gray-200 bg-opacity-20 backdrop-blur-lg py-8': this.expandMobileMenu,
                'max-h-0 lg:max-h-screen': !this.expandMobileMenu,
                '-translate-y-full bg-gray-200 bg-opacity-40 backdrop-blur-lg' : this.slide,
                'translate-y-0 bg-transparent': !this.slide
            }
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll(){
            if (window.innerWidth < 1024) return
            this.slide = window.scrollY > 64;
        },
        expandSubLink(link){
            if (window.innerWidth < 1024) return
            if (link.sub_links && link.sub_links.length > 0){
                link.is_visible = true
            }
        },
        collapseSubLink(link){
            if (window.innerWidth < 1024) return
            link.is_visible = false
        },
        openCloseSubLink(link){
            if (link.sub_links && link.sub_links.length > 0){
                link.is_visible =! link.is_visible
            }
        },
        openCloseMobileMenu(){
            this.expandLanguages = false
            this.links.map(x => x.is_visible = false)
            this.expandMobileMenu =! this.expandMobileMenu
        }
    }
}
</script>

<style scoped>
    .link:hover::after {
        width: 100%;
        right: 0;
    }

    .link::after {
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: "";
        display: block;
        height: 4px;
        left: 0;
        position: absolute;
        background: linear-gradient(to left, #f69ec4, #f9dd94 100%);
        transition: width .5s ease 0s, right .5s ease 0s;
        width: 0;
    }
</style>
