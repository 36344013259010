<template>
  <div class="font-poppins">
    <!-- Hero section -->
    <div id="main" class="pt-28 pb-16 md:py-40 md:flex md:items-center">
      <div class="max-w-6xl mx-auto px-4 flex flex-col md:flex-row md:items-center gap-10 w-full">
        <div class="md:w-1/2">
          <h1 class="font-medium text-2xl md:text-3xl lg:text-5xl mb-5 leading-8">
            Purchase and export of agricultural production
          </h1>
          <p class="text-gray-600 mb-8 font-light">
            NOKA Trading LLC is an advanced grain trader, and a key exporter on the grain market of Kazakhstan.
          </p>

          <a href="/#products" class="bg-amber-300 px-7 py-3 rounded text-sm font-medium cursor-pointer shadow">Our Products</a>
        </div>

        <div class="md:w-1/2">
          <div class="rounded bg-h1 bg-cover h-60 md:h-96 mb-3"></div>

          <div class="hidden md:flex gap-3">
            <div class="rounded bg-h2 bg-cover w-1/3 h-40"> </div>
            <div class="rounded bg-h3 bg-cover bg-center w-2/3 h-40"> </div>
          </div>
        </div>
      </div>
    </div>

    <!-- About section -->
    <div id="about" class="max-w-6xl mx-auto px-4 w-full mb-24">
      <div class="lg:w-1/2">
        <h3 class="text-xl md:text-2xl lg:text-3xl font-medium mb-2">About us</h3>
        <p class="text-gray-600 mb-12 font-light">NOKA Trading LLC employs experienced and highly-qualified
          professionals interested in the progress and development of the company on international market.</p>
      </div>

      <div class="flex flex-col lg:flex-row gap-10 lg:gap-4">
        <div class="lg:w-2/3 flex h-56 md:h-72 lg:h-auto lg:min-h-full gap-4">
          <div class="w-1/2 flex flex-col gap-4">
            <div class="rounded bg-a1 bg-cover w-full h-full"></div>
            <div class="rounded bg-a2 bg-cover bg-center w-full h-full"></div>
          </div>
          <div class="w-1/2">
            <div class="rounded bg-a3 bg-center bg-cover w-full h-full"></div>
          </div>
        </div>

        <div class="lg:w-1/3">
            <div class="bg-gray-800 text-white p-6 rounded mb-4">
              <div class="flex items-center mb-3">
                <img src="/img/p.png" alt="icon" class="w-5 mr-2"/>
                <h4 class="font-medium">Our mission</h4>
              </div>
              <p class="text-sm font-light">
                Providing our customers with high-quality raw materials and taking a dominant position as a
                supplier of grain and oil seeds from Kazakhstan to international market.
              </p>
            </div>

          <div class="bg-amber-100 p-6 rounded">
            <div class="flex items-center mb-3">
              <img src="/img/p.png" alt="icon" class="w-5 mr-2"/>
              <h4 class="font-medium">Our strategy</h4>
            </div>
            <p class="text-sm font-light">
              Increasing the company’s export capacity by intensifying trade and procurement on the
              grain market, as well as the expansion and opening of new markets for Kazakhstan’s grains and oilseeds.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Products section -->
    <div id="products" class="max-w-6xl mx-auto px-4 w-full mb-24">
      <div class="lg:w-1/2">
        <h3 class="text-3xl font-medium mb-2">Products</h3>
        <p class="text-gray-600 font-light mb-12">The company’s core activity is exporting grain and oil grown by the industry’s large enterprises.</p>
      </div>

      <div class="flex flex-col gap-4">
        <div class="flex gap-3 mb-5 overflow-x-auto no-scrollbar">
          <div
            v-for="c in categories"
            :key="c.id"
            class="border px-6 py-3 rounded font-medium cursor-pointer shrink-0"
            :class="{'bg-amber-200': c_id === c.id}"
            @click="chooseCat(c.id)"
          >
            <div class="flex items-center">
              <img :src="'/img/'+c.icon" alt="icon" class="w-5 h-5 mr-2"/>
              {{c.name}}
            </div>
          </div>
        </div>

        <div class="flex flex-wrap gap-4">
          <div
            v-for="p in products"
            :key="p.id"
            class="rounded md:w-1/3 lg:w-1/4 border bg-cover bg-center cursor-pointer hover:bg-gray-100 hover:-translate-y-2"
            :class="p.img"
            @click="goTo(p.id)"
          >
            <div class="p-6 w-full h-full bg-black bg-opacity-50 rounded text-gray-200">
              <h5 class="font-medium mb-2">{{p.name}}</h5>
              <p class="text-sm font-light line-clamp-3 mb-4">{{p.description}}</p>
              <a class="text-sm font-medium">Learn more</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Workflow -->
    <div id="workflow" class="max-w-6xl mx-auto px-4 w-full mb-24">
      <div class="w-1/2">
        <h3 class="text-3xl font-medium mb-12">Workflow</h3>
      </div>

      <div class="flex flex-col gap-10">

        <div class="rounded border flex bg-w1 bg-cover md:bg-none">
          <div class="md:w-1/2 lg:h-96 bg-w1 bg-cover bg-center rounded-l"></div>
          <div class="md:w-1/2 p-8  md:bg-transparent text-white rounded md:text-gray-700">
            <span class="font-medium text-2xl text-gray-300 md:text-gray-500">01</span>
            <h4 class="font-medium mb-2">Purchase of Goods</h4>
            <div class="font-light">
              <p class="mb-1">
                NOKA Trading LLC procures grains and oil seeds from agricultural producers at
                licensed linear elevators and grain reception centers pursuant to grain bills of sale.
              </p>
              <p>
                Conditions of purchase: FCA (incoterms 2020).
              </p>
            </div>
          </div>
        </div>

        <div class="rounded border flex bg-w2 bg-cover md:bg-none">
          <div class="md:w-1/2 lg:h-96 bg-w2 bg-cover bg-center rounded-l"></div>
          <div class="md:w-1/2 p-8 bg-black bg-opacity-60 md:bg-transparent text-white w-full h-full rounded md:text-gray-700">
            <span class="font-medium text-2xl text-gray-300 md:text-gray-500">02</span>
            <h4 class="font-medium mb-2">Grain Storage</h4>
            <div class="font-light">
              <p class="mb-1">
                As stated by the Law of the Republic of Kazakhstan “On Grain”, as well as the Rules for Generation, Storage, Refreshment, Transfer and Use of Grain Resources,
                NOKA Trading LLC selects grain reception points according to the prescribed manner.
              </p>
              <p>
                The activities of grain reception points are governed by regulatory legal acts of the Republic of Kazakhstan, including the Rules for Quantity and Quality Grain Accounting;
                Rules for Grain Storage; Rules for Issuance, Circulation and Redemption of Grain Bills of Sale.
              </p>
            </div>
          </div>
        </div>

        <div class="rounded border flex bg-w3 bg-cover md:bg-none">
          <div class="md:w-1/2 lg:h-96 bg-w3 bg-cover bg-center rounded-l"></div>
          <div class="md:w-1/2 p-8 bg-black bg-opacity-60 md:bg-transparent text-white w-full h-full rounded md:text-gray-700">
            <span class="font-medium text-2xl text-gray-300 md:text-gray-500">03</span>
            <h4 class="font-medium mb-2">Transportation</h4>
            <div class="font-light">
              <p>
                Grains, oilseeds and legumes are transported by railroad and water. Astyk Trans JSC serves as the main partner for transportation of grain by railroad. It should be noted that
                the total car fleet operated by Astyk Trans JSC is up to 10,000 grain carrier units, which allows us to transport grain in any period of time.
              </p>
            </div>
          </div>
        </div>

        <div class="rounded border flex bg-w4 bg-cover md:bg-none">
          <div class="md:w-1/2 lg:h-96 bg-w4 bg-cover bg-center rounded-l"></div>
          <div class="md:w-1/2 p-8 bg-black bg-opacity-60 md:bg-transparent text-white w-full h-full rounded md:text-gray-700">
            <span class="font-medium text-2xl text-gray-300 md:text-gray-500">04</span>
            <h4 class="font-medium mb-2">Export destinations</h4>
            <div class="font-light flex flex-col gap-1">
              <p>- Turkey: CIF Mersin, Samsun and etc</p>
              <p>- Italy: CIF ECI & WCI</p>
              <p>- Spain: CIF Castellon</p>
              <p>- Belgium: CIF Ghent</p>
              <p>- China: FCA & DAP Alashankou</p>
              <p>- Uzbekistan: DAP Saryagash</p>
              <p>- Turkmenistan: DAP Bolashak</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div id="contact" class="w-full text-gray-500">
      <div class="max-w-6xl mx-auto px-4 w-full">
        <div class="border-t py-10 flex flex-col gap-10">
          <div class="md:w-1/2 lg:w-1/3">
            <img src="/img/noka.png" alt="logo" class="w-40 mb-3"/>
            <p class="text-xs font-light">NOKA Trading LLC is an advanced grain trader and a key player on the grain market of Kazakhstan.</p>
          </div>

          <div class="md:w-2/3 flex flex-col md:flex-row gap-10 md:gap-40">
            <div>
              <h5 class="font-medium text-black">Explore</h5>
              <div class="text-sm flex flex-col gap-4 pt-5">
                <a>Main</a>
                <a>About</a>
                <a>Products</a>
                <a>Contact</a>
              </div>
            </div>

            <div>
              <h5 class="font-medium text-black">Contact</h5>
              <div class="text-sm flex flex-col gap-4 pt-5">
                <a class="flex items-center cursor-pointer" href="tel:+971585119341">
                  <img src="/img/phone.svg" alt="phone" class="w-4 mr-2"/>
                  +97 158 511 93 41
                </a>
                <a class="flex items-center cursor-pointer" href="mailto:office@nokatrading.com">
                  <img src="/img/mail.svg" alt="mail" class="w-4 mr-2"/>
                  office@nokatrading.com
                </a>
                <a class="flex items-center cursor-pointer">
                  <img src="/img/location.svg" alt="address" class="w-4 mr-2"/>
                  Jabal Ali Industrial First, Sabha Build, PO Box 599-1900, Dubai / UAE
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="text-sm font-light text-center border-t-2 py-4">
          Copyright © NOKA Trading LLC. All rights reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data: () => ({
    c_id: 1,
    products: [],
    categories: [
      {
        id: 1,
        name: 'Grains',
        icon: 'wheat.svg',
        products: [
          {
            id: 1,
            name: 'Wheat',
            img: 'bg-p1',
            description: 'Wheat is the main grain crop. It falls into spring and autumn varieties according to ' +
                'its seeding time. In terms of botanical characteristics, it is divided into basic types: ' +
                'common and durum.'
          },
          {
            id: 2,
            name: 'Barley',
            img: 'bg-p2',
            description: 'Barley is a fast-ripening (its vegetation period lasts 70 days) and widely-grown spring crop.' +
                ' It falls into a six-row and two-row varieties.'
          },
          {
            id: 3,
            name: 'Corn',
            img: 'bg-p3',
            description: 'According to the shape and formation of the ear and grain, corn falls into flint, ' +
                'dent, half-dent, sweet, pod, soft, waxy, snap and other varieties.'
          }
        ]
      },
      {
        id: 2,
        name: 'Oil seeds',
        icon: 'flax-seed.svg',
        products: [
          {
            id: 5,
            name: 'Sunflowers',
            img: 'bg-p5',
            description: 'Sunflowers are cross-pollinated plants, which greatly complicates' +
                ' the classification of their various forms, varieties and hybrids.'
          },
          {
            id: 6,
            name: 'Rapeseed',
            img: 'bg-p6',
            description: 'Rapeseed is a herbaceous plant of the cabbage family with an extremely ' +
                'developed root (stem) that penetrates into the soil up to 3 m.'
          },
          {
            id: 7,
            name: 'Flax',
            img: 'bg-p7',
            description: 'The oil content of flax oilseeds is 42 to 44%, and they hold up to 23 % protein.'
          }
        ]
      }
    ]
  }),
  mounted() {
    this.chooseCat(this.c_id)
  },
  methods: {
    chooseCat(id) {
      this.c_id = id
      this.products = this.categories[id - 1].products
    },
    goTo(id) {
      this.$router.push({name: 'details', params: {id: id}})
    }
  }
}
</script>
