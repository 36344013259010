<template>
  <div class="text-gray-800 font-poppins">
    <!-- Details section -->
    <div class="max-w-6xl mx-auto px-4 py-24 md:py-32 lg:py-40 w-full lg:mb-10">
      <p v-if="Object.keys(product).length === 0" class="font-light">Not found</p>
      <div class="max-w-xl mx-auto" v-else>
        <h3 class="text-3xl font-medium mb-5 text-center">{{product.name}}</h3>
        <div v-html="product.description" class="font-light mb-10"></div>

        <img :src="product.img" :alt="product.name" class="rounded object-cover object-center h-96 w-full">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  props: ['id'],
  data: () => ({
    product: {},
    products: [
      {
        id: 1,
        name: 'Wheat',
        img: '/img/p1.jpg',
        gost: 'Quality Indicator: ST RK 10-46-2008',
        description: '<p class="mb-1.5">Wheat is the main grain crop. It falls into spring and autumn varieties according to its seeding time. ' +
            'In terms of botanical characteristics, it is divided into basic types: common and durum.</p>' +
            '<p class="mb-1.5">The grain of common wheat has a glazed, chalky or mealy consistency, rounded or oval shape, which is ' +
            'slightly extended to the germ, as well as an evident brush and a deep crease. It can be white,' +
            ' red or yellow in color. Common wheat is used in confectionery and bread making.\n' +
            '\n</p>' +
            '<p class="mb-1.5">Durum wheat significantly differs from common one. The grain is ribbed and more elongated with a' +
            ' thickened dorsal surface near the germ. Its sectional view is glazed and translucent. ' +
            'The crease is open, poorly developed and does not goes deep inside the grain. Its color varies ' +
            'from light to dark amber. It is more rich in protein, sugar and minerals than common wheat. ' +
            'Durum wheat is used for pasta and semolina, added when grinding wheat with poor baking properties ' +
            'and obtaining granular flour.</p>'
      },
      {
        id: 2,
        name: 'Barley',
        img: '/img/p2.jpg',
        gost: 'GOST 28672-90',
        description: 'Barley is a fast-ripening (its vegetation period lasts 70 days) and widely-grown spring crop. It falls into a six-row and two-row varieties. Barley is used to make pearl and peeled barley groats and partially to obtain flour and malt. ' +
            'This grain crop serves as the main raw material in beer brewing and used as animal forage.'
      },
      {
        id: 3,
        name: 'Corn',
        img: '/img/p3.jpg',
        gost: 'GOST 13634-90',
        description: 'According to the shape and formation of the ear and grain, corn falls into flint, dent, ' +
            'half-dent, sweet, pod, soft, waxy, snap and other varieties. As compared to other crops, it ' +
            'contains less protein, but has more fat (up to 5 %), mostly stored in the germ. The germ is separated ' +
            'and used for the production of oil. ' +
            'Corn is processed into cereals, starch, alcohol and molasses.'
      },
      {
        id: 4,
        name: 'Buckwheat',
        gost: 'GOST 5550-74',
        description: 'Buckwheat is a three-edged fruit, covered with dense fruit coat, rather than floral shell, ' +
            'as grain varieties have. Under the coat, there is a kernel consisting of episperm, aleurone layer, ' +
            'endosperm and a large germ in the form of a S-shaped curved plate. The fruit of buckwheat is a three-edged' +
            ' nut that is gray,' +
            ' brown or black in color. A hundred fruit weights 20 to 30 g, and the husk content is 18 to 30%.'
      },
      {
        id: 5,
        name: 'Sunflowers',
        img: '/img/p5.jpg',
        gost: 'GOST 22391-89',
        description: 'Sunflowers are cross-pollinated plants, which greatly complicates the classification of their various forms, ' +
            'varieties and hybrids. According to the modern classification, sunflowers belong to the Asteraceae family. ' +
            'Heliа́nthus а́nnuus L. is considered to be a collective category, embracing two individual species of sunflowers:' +
            ' Heliа́nthus cúltus Wenzl., the cultivated sunflower, and Heliа́nthus ruderа́lis Wenzl., the wild sunflower. ' +
            'The former individual species bring together all cultivated field cultures, while the latter consolidates ' +
            'its various wild forms. They tend to strong branching of the stem, form small calathides and small seed pod ' +
            'that shatter when ripe.'
      },
      {
        id: 6,
        name: 'Rapeseed',
        img: '/img/p6.jpg',
        gost: 'GOST 10583-76',
        description: 'Rapeseed is a herbaceous plant of the cabbage family with an extremely developed root (stem) ' +
            'that penetrates into the soil up to 3 m. The main part of the root lies at the depth of 20 to 30 cm,' +
            ' which is of great technical importance in enhancing soil fertility. The type of inflorescence is a raceme, ' +
            'flowers are lemon-yellow, with 20 to 37 units at the central branch. The fruit is a pod 6 to 12 cm long,' +
            ' with 20 to 28 seeds. 1,000 seeds weight 3.7 to 4.5 grams.'
      },
      {
        id: 7,
        name: 'Flax',
        img: '/img/7.jpg',
        gost: 'GOST 10582-76',
        description: '<p class="mb-1">The oil content of flax oilseeds is 42 to 44%, and they hold up to 23 % protein. ' +
            'Its seeds are used to produce oil, which is mainly applied for technical purposes. Its ability to ' +
            'rapidly dry forming a solid thin and flexible film makes it possible to manufacture high-quality' +
            ' paint oil, as well as varnishes and enamels.</p>' +
            '<p class="mb-1">Flax oil has a world lead among technical oils by overall production. It is commonly used' +
            ' in electrical engineering, paper and soap industries, as well as in the manufacturing medicine and ' +
            'perfumes. Flax oil is used as a food component in small amounts.</p>' +
            '<p class="mb-1">As compared to oilseeds and legumes, flax stands out due to its nutritional value and ' +
            'functional properties. Flax seeds are extremely rich in fat с with high levels of α-linolenic acid ' +
            '(hereinafter ALA) ‑ fatty acid of the omega-3 family, dietary fiber, protein with a high biological value, ' +
            'lignans and phenolic compounds. Due to the high content of phytocompounds with phytoestrogenic activity' +
            ' (lignans) and ALA, flax seeds fall into the functional food category.</p>'
      },
      {
        id: 8,
        name: 'Peas',
        gost: 'GOST 28674-90',
        description: 'Peas originate from Afghanistan and Eastern India. The fruit of peas is bean, consisting of ' +
            'valves and seeds. According to the structure of valves, peas fall into sugar and shelling varieties. ' +
            'Beans of sugar species are eaten along with their seeds in the form of so-called pods. The valves of ' +
            'shelling species are not edible. When seeds ripen, pod valves easily shell. Therefore, these varieties of' +
            ' peas are called shelling.'
      },
      {
        id: 9,
        name: 'Soy',
        gost: 'GOST 17109-88',
        description: 'Soy is a universal world legume crop. Soy is used to obtain flour, butter, milk and cheese. ' +
            'It is added to confectionery, canned food, sauces and other food products. Soy is used only after it is' +
            ' industrially processed. Soybeans are not edible in their natural form.'
      }
    ]
  }),
  mounted() {
    let idx = parseInt(this.id)
    let index = this.products.findIndex(x => x.id === idx)
    if (index >= 0) {
      this.product = this.products[index]
    }
  }
}
</script>
